<template>
  <multiselect
    v-if="visible"
    v-model="selected"
    :placeholder="placeholder"
    :internal-search="false"
    :options="results"
    :searchable="true"
    :loading="isLoading"
    :options-limit="20"
    :max-height="600"
    :custom-label="customLabel"
    @search-change="asyncFindApi"
    @select="onSelect"
    @open="hideOthers"
    @close="showOthers"
  />
</template>

<script>
import multisearch from '@/mixins/multisearch';
import debounce from '@/mixins/debounce';

export default {
  mixins: [
    multisearch, //
    debounce, //
  ],

  data() {
    return {
      type: 'song',
      placeholder: 'Song Search',
    };
  },

  methods: {
    onSelect(option) {
      // console.log('onSelect', option);
      Bus.$emit('songs:fetch', {
        id: option.id,
        medium: this.medium,
      });
      Bus.$emit('searches:clear', 'song');
    },

    customLabel(option) {
      let name;

      switch (true) {
        case option.artist !== '' && option.title !== '':
          name = `${option.artist} - ${option.title}`;
          break;

        case option.artist !== '' && option.title === '':
          name = `${option.artist}`;
          break;

        case option.artist === '' && option.title !== '':
          name = `${option.title}`;
          break;

        default:
          name = option.file;
          break;
      }

      return $('<div/>').html(name).text();
    },

    asyncFindApi(query) {
      this.debounce(() => {
        // console.log('asyncFindApi.query', query);
        this.results = [];
        this.isLoading = true;

        this.$http.get('/api/v1/search/songs', { params: { query } }).then((res) => {
          // console.log('res.data', res.data);
          this.results = res.data;
          this.isLoading = false;
        });
      }, 200);
    },
  },
};
</script>
